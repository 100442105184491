input {
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgray;
}

input:focus {
    outline: none;
    border-bottom: 1px solid #DFC19D;
    padding: .5rem;
}

textarea {
    width: 100%;
    height: 150px;
    border: none;
    border-bottom: 1px solid lightgray;
}

textarea:focus {
    outline: none;
    border-bottom: 1px solid #DFC19D;
    padding: .5rem;
}

.contact {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #8A7357;
}

.contact:hover {
    color: #DFC19D;
}
