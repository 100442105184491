.all-content {
    position: relative;
    min-height: 100vh;
}

.pf-text {
    font-family: "Playfair Display", serif;
}

.header-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: #8A7357;
}

.text {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #5E5E5E;
}

/* footer text font override */
footer {
    font-size: small;
}

/* true <button> styling for contact page */
button {
    background-color: #DFC19D;
    border: none;
    text-decoration: none;
    color: black;
    font-weight: 600;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

/* "button" styling for <a> tag buttons */
.button {
    display: inline-block;
    background-color: #DFC19D;
    text-decoration: none;
    color: black;
    font-size: medium;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

/* "button" styling for home page <a> quote button */
.button-home:hover {
    background-color: rgb(237, 224, 209);
}

.button-home:focus {
    background-color: rgb(237, 224, 209);
}

/* "button" styling for <a> email quote buttons */
.button-email:hover {
    background-color: rgb(237, 224, 209);
    filter: drop-shadow(0mm 1mm 1mm rgb(192, 192, 192));
}

.button-email:focus {
    background-color: rgb(237, 224, 209);
    filter: drop-shadow(0mm 1mm 1mm rgb(192, 192, 192));
}

.content-wrapper {
    position: relative;
    min-height: 100vh;
}
  
.content-wrapper {
    padding-bottom: 8rem;
}
  
footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8rem;
    background-color: rgb(240, 240, 240);
}