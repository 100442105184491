#dropdown {
    border: none;
    border-radius: 0px;
}

#dropdown-menu {
    border: none;
    border-radius: 0px;
    filter: drop-shadow(0mm 1mm 1mm rgb(192, 192, 192));
}

#dropdown-item {
    font-family: "Open Sans", sans-serif;
    color: #5E5E5E;
}

#dropdown-item:hover {
    color: #8A7357;
}

.nav-link {
    font-family: "Open Sans", sans-serif;
    color: #5E5E5E;
}

.nav-link:hover {
    color: #8A7357;
}

#topNav > li > a.active {
    color: #8A7357;
    text-decoration: underline 1px;
}